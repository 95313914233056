import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const RESET_PASSWORD_FORM_COMPONENT_KEY = 'ResetPasswordFormComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  onSuccessStrategy: 'drawer',
  password: {
    simple: false
  },
  input: {
    animatedLabel: true
  }
}
