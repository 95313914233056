












import { Component, Vue } from 'vue-property-decorator'
import { benefitsIconsRegistry } from '../Newsletter.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Benefits>({
  name: 'Benefits'
})
export class Benefits extends Vue {
  public benefitIcons = benefitsIconsRegistry

  public get icons () {
    return Object.entries(this.benefitIcons).map(([k, v]) => {
      return {
        icon: v,
        label: String(this.$t(`front.profile.views.newsletter.benefitsIcons.${k}`))
      }
    })
  }
}

export default Benefits
