

















import { Component, Inject, Prop, Vue } from 'vue-property-decorator'

import Benefits from './Benefits.vue'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NewsletterAccepted>({
  name: 'NewsletterAccepted',
  components: {
    Benefits
  }
})
export class NewsletterAccepted extends Vue {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: true })
  public readonly email!: string
}

export default NewsletterAccepted
