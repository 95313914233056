














































import { Component, Mixins } from 'vue-property-decorator'

import { AddressData, AddressType, RequestDataChangePayload } from '../../../../contexts'

import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { UserRefreshMixin } from '../../../shared/mixins/userRefresh.mixin'

import {
  ChangeAddressFormConfigurable,
  SuccessfulUpdatePayload
} from '../../organisms/ChangeAddressForm'
import { ChangeAddressFormFields } from '../../molecules/ChangeAddressFormFields'
import { ProfileWrapper } from '../../molecules/ProfileWrapper'
import { UserMixin } from '../../shared'

import AdditionalAddresses from './partials/AdditionalAddresses.vue'
import DefaultAddress from './partials/DefaultAddress.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<AddressesBillings>({
  name: 'AddressesBillings',
  components: {
    AdditionalAddresses,
    ChangeAddressFormConfigurable,
    ChangeAddressFormFields,
    DefaultAddress,
    Loader,
    ProfileWrapper,
    SimpleForm
  },
  beforeDestroy () {
    this.addressPayload = undefined
  }
})
export class AddressesBillings extends Mixins(UserMixin, UserRefreshMixin) {
  /** Address data passed to the form component */
  public addressPayload?: AddressData
  /** Define type of address: Bolling / Shipping
   * @see AddressType
   */
  public addressType?: string
  /** Define whether new address should be set as default */
  public defaultAddress: boolean = false

  public isEditing: boolean = false

  public defaultType = AddressType

  /**
   * Get default shipping address from list of addresses
   */
  public get defaultShippingAddress (): Array<AddressData> | undefined {
    if (!this.user) {
      return
    }

    return this.user.addresses.filter((address) => address.defaultShipping)
  }

  /**
   * Get default billing address from list of addresses
   */
  public get defaultBillingAddress (): Array<AddressData> | undefined {
    if (!this.user) {
      return
    }

    return this.user.addresses.filter((address) => address.defaultBilling)
  }

  /**
   * Get not default addresses
   */
  public get genericAddresses (): Array<AddressData> | undefined {
    if (!this.user) {
      return
    }

    return this.user.addresses.filter((address) => !address.defaultBilling && !address.defaultShipping)
  }

  /**
   * Check whether component should be in editing state
   */
  public get isEditingValue (): boolean {
    return this.isEditing
  }

  /**
   * Updates user store object
   * @param payload
   */
  public onSuccessfulUpdate (payload: SuccessfulUpdatePayload) {
    if (!this.user) {
      return
    }

    /**
     * @inheritDoc
     */
    this.getUpdatedUserData()
  }

  /**
   * Request data change
   * @param payload
   */
  public requestEdit (payload: RequestDataChangePayload) {
    this.addressPayload = payload?.data
    this.addressType = payload?.type
    this.defaultAddress = payload.default

    this.isEditing = true
  }
}

export default AddressesBillings
