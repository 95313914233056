

































import { Component, Inject, Prop, Watch } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

import { ToastMixin } from '../../../../shared'

import Benefits from './Benefits.vue'
import NewsletterClaimExtended from './NewsletterClaimExtended.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NewsletterRequest>({
  name: 'NewsletterRequest',
  components: {
    Benefits
  },
  created () {
    this.initFormData()
  }
})
export class NewsletterRequest extends ToastMixin {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: true })
  public readonly email!: string

  public isValidEmail: boolean = false
  public emailErrors: string[] = []
  public formData = {
    email: ''
  }

  public get accordionItems () {
    return Array.from(Array(1).keys()).map(() => {
      return {
        id: 'newsletter-acc-1',
        label: String(this.$t('front.profile.views.newsletter.showMore')),
        isOpen: false,
        content: {
          component: NewsletterClaimExtended
        }
      }
    })
  }

  public requestSubscribe (): void {
    this.$emit('subscribe', this.formData)
  }

  protected initFormData (): void {
    if (!this.email) {
      return
    }

    this.formData = {
      email: this.email
    }

    this.emailErrors = []
    this.isValidEmail = true
  }

  @Watch('formData.email')
  protected onFormDataChange (value: string) {
    this.isValidEmail = RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/gi).test(value)

    if (!this.isValidEmail) {
      this.emailErrors = []
      this.emailErrors.push(String(this.$t('front.profile.views.newsletter.validEmailError')))
    } else {
      this.emailErrors = []
    }
  }
}

export default NewsletterRequest
