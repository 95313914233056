






import { Component, Vue } from 'vue-property-decorator'

import { ResetPasswordForm } from '../organisms/ResetPasswordForm/ResetPasswordForm.vue'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<ResetPassword>({
  name: 'ResetPassword',
  components: { ResetPasswordForm }
})
export class ResetPassword extends Vue {}

export default ResetPassword
