


























import { Component, Mixins } from 'vue-property-decorator'

import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType, IEventbus
} from '@movecloser/front-core'

import { Inject, logger } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'
import { StructureConfigurable } from '../../../../support/mixins'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { UserMixin } from '../../../profile/shared'

import { AuthControlServiceType, IAuthControl } from '../../contracts'
import {
  authSSOCustomerData,
  authSSOParamsKey,
  authSSOSelectedProvider,
  authSSOSessionId, authSSOSource
} from '../../config/auth'
import { UserModel } from '../../shared'
import {
  CONSENTS_REQUEST_VIEW_CONFIG_MAP,
  CONSENTS_REQUEST_VIEW_KEY
} from './ConsentsRequest.config'
import FormErrorsMixin from '../../../../support/mixins/FormErrors.mixin.vue'
import { RouteNames } from '../../../profile/routes'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ConsentsRequest>({
  name: 'ConsentsRequest',
  components: { Consents },
  created () {
    this.config = this.getComponentConfig(CONSENTS_REQUEST_VIEW_KEY, { ...CONSENTS_REQUEST_VIEW_CONFIG_MAP })
    this.composeConsentsPayload()
  }
})
export class ConsentsRequest extends Mixins(FormErrorsMixin, StructureConfigurable, UserMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public config!: AnyObject
  public consentsPayload: Record<string, boolean> | null = null
  public error: string = ''

  /**
   * Determines the consents of form.
   */
  public get consentsConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('consents')
  }

  /**
   * Collection of required consents
   */
  public get requiredConsents () {
    return this.consentsConfig
      .filter((consent: AnyObject) => consent.required)
      .map((consent: AnyObject) => consent.option)
  }

  /**
   * Retrieves a site logo
   */
  public get siteData () {
    return {
      logo: this.siteService.getActiveSite().logo.light,
      name: this.siteService.getActiveSite().name
    }
  }

  /**
   * Handles consents acceptance action
   */
  public async acceptConsents (): Promise<void> {
    const SSOParams = localStorage.getItem(authSSOParamsKey)
    const selectedProvider = localStorage.getItem(authSSOSelectedProvider)
    const SSOCustomer = localStorage.getItem(authSSOCustomerData)
    const sessionId = localStorage.getItem(authSSOSessionId)

    if (!SSOParams || !selectedProvider || !sessionId) {
      return
    }

    this.validateConsents()
    if (this.error.length > 0) {
      return
    }

    try {
      const response = await this.authControl.setSocialCallback(
        JSON.parse(SSOParams),
        selectedProvider,
        JSON.parse(sessionId).id,
        true
      )

      if (response && response.token && response.token.accessToken) {
        /** Set token for authorization */
        this.authService.setToken(response.token.toAuthToken())

        this.eventBus.emit(
          'app:authorization.sign_up',
          {
            email: SSOCustomer ? JSON.parse(SSOCustomer).email : null,
            firstName: SSOCustomer ? JSON.parse(SSOCustomer).name.split(' ')[0] : null,
            lastName: SSOCustomer ? JSON.parse(SSOCustomer).name.split(' ')[1] : null,
            emailOffers: this.consentsPayload ? this.consentsPayload.emailOffers : false,
            smsOffers: this.consentsPayload ? this.consentsPayload.smsOffers : false
          }
        )

        await this.$router.push({
          name: `profile.${RouteNames.Profile}`,
          params: { triggerEvent: 'login' }
        })

        /**
         * @inheritDoc
         */
        ConsentsRequest.RemoveSSOStorageKeys()
      }
    } catch (e) {
      logger(e, 'warn')
    }
  }

  protected composeConsentsPayload () {
    for (const option of Object.values(this.consentsConfig)) {
      this.consentsPayload = {
        ...this.consentsPayload,
        [option.option]: false
      }
    }
  }

  private validateConsents (): void {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const [consentKey, consentValue] of Object.entries(this.consentsPayload!)) {
      if (this.requiredConsents.includes(consentKey) && !consentValue) {
        this.error = this.$t('front.auth.views.consentsRequest.validationError').toString()
        return
      } else {
        this.error = ''
      }
    }
  }

  /**
   * Handles removal of all storage items that were used while authentication
   */
  private static RemoveSSOStorageKeys (): void {
    localStorage.removeItem(authSSOParamsKey)
    localStorage.removeItem(authSSOSelectedProvider)
    localStorage.removeItem(authSSOCustomerData)
    localStorage.removeItem(authSSOSessionId)
    localStorage.removeItem(authSSOSource)
  }
}

export default ConsentsRequest
