




import { Component, Mixins } from 'vue-property-decorator'
import { Authentication, AuthServiceType, EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject } from '../../../support'

import { FullscreenLoader } from '../../shared/molecules/Loader'

import { AuthControlServiceType, IAuthControl } from '../contracts'
import { authSSOSelectedProvider } from '../config/auth'
import { SSOAuthMixin, UserModel } from '../shared'

/**
 * Middleware component that handles SSO authentication
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<SSOMiddleware>({
  name: 'SSOMiddleware',
  components: { FullscreenLoader },
  async mounted (): Promise<void> {
    const selectedProvider = localStorage.getItem(authSSOSelectedProvider)

    await this.setSSOCallback(selectedProvider)
  }
})
export class SSOMiddleware extends Mixins(SSOAuthMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus
}
export default SSOMiddleware
