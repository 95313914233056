












import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<NewsletterClaimExtended>({
  name: 'NewsletterClaimExtended'
})
export class NewsletterClaimExtended extends Vue {
  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public get privacyPath () {
    const privacy = this.siteService.getActiveSiteUrls().privacy
    return privacy || '/'
  }
}

export default NewsletterClaimExtended
