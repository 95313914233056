














































import { Component, Mixins, Inject as VueInject } from 'vue-property-decorator'
import { Authentication, AuthServiceType, EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'

import { IProfileService, ProfileServiceType } from '../../contracts'
import { UserModel } from '../../../auth/shared'

import { UserMixin } from '../../shared'
import { ProfileWrapper } from '../../molecules/ProfileWrapper'

import NewsletterAccepted from './partials/NewsletterAccepted.vue'
import NewsletterRequest from './partials/NewsletterRequest.vue'
import NewsletterSubscribed from './partials/NewsletterSubscribed.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<Newsletter>({
  name: 'Newsletter',
  components: {
    NewsletterAccepted,
    NewsletterRequest,
    NewsletterSubscribed,
    ProfileWrapper
  },
  created () {
    this.initNewsletterView()
  }
})
export class Newsletter extends Mixins(UserMixin, ToastMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  public subscribedEmail: string = ''

  public resignFromNewsletter: boolean = false

  public newsletterAccept: boolean = false

  public wasSuccessfullySubscribed: boolean = false

  public get subscribedUserEmail (): string {
    return this.subscribedEmail
  }

  public get newsletterResign (): boolean {
    return this.resignFromNewsletter
  }

  public requestResign (): void {
    this.resignFromNewsletter = true
  }

  public cancelResign (): void {
    this.resignFromNewsletter = false
  }

  public onContinue (): void {
    this.wasSuccessfullySubscribed = false
  }

  public handleResign () {
    this.eventBus.emit('app:newsletter.unsubscribe', {
      email: this.user?.email
    })

    if (!this.user) {
      return
    }
    this.subscribedEmail = this.user.email

    setTimeout(() => {
      this.resignFromNewsletter = false
      this.newsletterAccept = false

      this.showToast(String(this.$t('front.profile.views.newsletter.responses.unsubscribe.success')), ToastType.Success)
    }, 1000)
  }

  public handleSubscribe (payload: { email: string }) {
    this.eventBus.emit('app:newsletter.subscribe', {
      email: payload.email
    })

    this.subscribedEmail = payload.email

    setTimeout(() => {
      this.newsletterAccept = true
      this.wasSuccessfullySubscribed = true
    }, 1000)
  }

  protected async initNewsletterView () {
    if (this.authService.token) {
      const response = await this.profileService.getUserSubscriptions(this.authService.token.accessToken)

      this.newsletterAccept = response.agreements.email
      this.subscribedEmail = response.email
    }
  }
}

export default Newsletter
