
























import { Component, Inject as VueInject } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../support'

import { NavTabsItemProps } from '../../../dsl/molecules/NavTabs'

import { FullscreenLoader } from '../../shared/molecules/Loader'
import { RouteNames } from '../../profile/routes'
import { RouteNames as AuthRouteNames } from '../../auth/routes'
import { UserMixin } from '../../profile/shared'

import { LoginForm } from '../organisms/LoginForm'
import { SignupForm } from '../organisms/SignupForm'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Auth>({
  name: 'Auth',
  components: { FullscreenLoader, LoginForm, SignupForm }
})
export class Auth extends UserMixin {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: defaultProvider<boolean>(false) })
  protected readonly isMobileCallback!: () => boolean

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public mode: 'login' | 'register' = 'login'

  /**
   * Determines the options for tabs.
   */
  public get authModes (): NavTabsItemProps[] {
    return [
      {
        id: 'login',
        label: this.$t('front.auth.forms.logIn.mode.login').toString(),
        active: true
      },
      {
        id: 'register',
        label: this.$t('front.auth.forms.logIn.mode.register').toString(),
        active: false
      }
    ]
  }

  /**
   * Determines the form to be rendered based on selected choice.
   */
  public get component (): VueConstructor {
    return this.mode === 'login' ? LoginForm : SignupForm
  }

  /**
   * Determines whether current device is mobile.
   */
  public get isMobile (): boolean {
    return this.isMobileCallback()
  }

  public onForgotPassword (): void {
    this.$router.push({
      name: `auth.${AuthRouteNames.RequestResetPassword}`
    })
  }

  public onSuccess (): void {
    this.$router.push({
      name: `profile.${RouteNames.Profile}`,
      params: { triggerEvent: 'login' }
    })
  }
}
export default Auth
