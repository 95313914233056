


























import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<SignUpWelcome>({
  name: 'SignUpWelcome'
})
export class SignUpWelcome extends Vue {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get siteData () {
    return {
      logo: this.siteService.getActiveSite().logo.light,
      name: this.siteService.getActiveSite().name
    }
  }
}

export default SignUpWelcome
