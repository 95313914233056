import { Component } from 'vue'

import Brands from './partials/icons/Brands.vue'
import Discounts from './partials/icons/Discounts.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum BenefitIcon {
  Brands = 'brands',
  Discount = 'discount'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const benefitsIconsRegistry: Record<string, Component> = {
  [BenefitIcon.Brands]: Brands,
  [BenefitIcon.Discount]: Discounts
}
